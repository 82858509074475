import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import { IService } from "./cache/Services";
import RegistrationForm from "./RegistrationForm";

const UpdateRegistrationForm: React.FC = () => {
  const params = useParams() as { token: string };
  const [token] = React.useState(params.token);
  const [existingRegistration, setExistingRegistration] = React.useState<IService>();
  React.useEffect(() => {
    axios.get(`https://gku-api.gku-pkn.nl/subscriptions/${token}`)
    .then((response: { data: IService }) => {
      setExistingRegistration(response.data);
    })
  }, [token]);

  if (existingRegistration) {
    return (<RegistrationForm existingRegistration={existingRegistration}/>);
  } else {
    return (<div>Loading...</div>);
  }
}

export default UpdateRegistrationForm;