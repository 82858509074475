import React from 'react';
import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import RegistrationForm from './RegistrationForm';
import logo from './assets/images/logo.png';
import { AppBar, Toolbar, makeStyles } from '@material-ui/core';
import ResultScreen from './components/Result';
import UnsubscribeForm from './UnsubscribeForm';
import CancellationResult from './CancellationResult';
import UpdateRegistrationForm from './UpdateRegistrationForm';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#51A38D',
    },
    secondary: {
      main: '#B94A48',
    }
  }
});

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: '48px',
  },
}));

 function App() {

  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <AppBar position="static">
          <Toolbar className="header">
            <img className="logo" alt="logo" src={logo} />
          </Toolbar>
        </AppBar>
        <Router>
          <Switch>
            <Route path="/result">
              <ResultScreen />
            </Route>
            <Route path="/wijzigen/:token">
              <UpdateRegistrationForm />
            </Route>
            <Route path="/cancellation">
              <CancellationResult />
            </Route>
            <Route path="/afmelden/:token">
              <UnsubscribeForm />
            </Route>
            <Route path="/">
              <RegistrationForm />
            </Route>
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
