import React from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  btnContainer: {
    paddingTop: '32px',
  },
}))

function CancellationResult() {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles()
  const { success, message } = location.state as { success: boolean, message: string };

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
    >
      <Grid item className="padding">
        { success ? (
          <>
            <h4>Afmelden gelukt</h4>
            <p>{message} Je bent succesvol afgemeld. Je ontvangt binnen enkele minuten een bevestigingsmail.</p>
          </>
        ) : (
          <>
            <h4>Het afmelden is helaas niet gelukt</h4>
            <p>{message}</p>
          </>
        ) }
      </Grid>
      <Grid item className={classes.btnContainer}>
        <Button fullWidth
            variant="outlined"
            onClick={() => {
              history.push('/');
            }}
            color="primary">
            Terug naar het formulier
          </Button>
      </Grid>
    </Grid>
  );
 }

 export default CancellationResult;

