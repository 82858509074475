import React from 'react';
import axios from 'axios';

export interface IService {
  id: number;
  dayId: number;
  day: {
    date: string;
  },
  description: string;
  time: {
    hours: number,
    minutes: number,
  },
  churchId: number;
  church: {
    name: string;
  }
  service: {
    id: number;
    churchId: number;
  }
  preacherId: number;
  totalSeats: number;
  subscribedSeats: number;
  availableSeats: number;
  openForSubscriptions: boolean;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  token: string;
  emailAddress: string;
  canBeUnsubscribed: boolean;
  additionalNamesList: string[];
  additionalNames: string;
};

export function useServices(): [IService[], React.Dispatch<React.SetStateAction<IService[]>>] {

  const [services, setServices] = React.useState<IService[]>([]);

  React.useEffect(() => {
    async function fetchServices() {
      try {

        const result = await axios('https://gku-api.gku-pkn.nl/subscriptions/available');
        setServices(result.data);

      } catch (error) {
        console.error('Could not fetch services data', error);
      }
    }

    fetchServices();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [services, setServices];
}