import React from 'react'; // we need this to make JSX compile
import { InputLabel, FormControl, TextField, makeStyles } from '@material-ui/core';
import { size, trim } from 'lodash';

type InputFieldProps = {
  placeholder: string;
  label: string;
  id: string;
  value: string;
  dirty: boolean;
  required?: boolean;
  type?: 'text' | 'email';
  onUpdate: (value: string) => void;
};

const emailCheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const useStyles = makeStyles(theme => ({
  xs12: {
    width: '100%',
  },
  label: {
    color: '#333333',
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '19px',
    fontFamily: 'Open Sans',
    paddingBottom: '8px',
    paddingTop: '3px',
  }
}))

export const InputField = ({
  placeholder,
  label,
  id,
  dirty,
  value,
  type = 'text',
  required = false,
  onUpdate,
}: InputFieldProps) => {

  const classes = useStyles();
  const [error, setError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');


  if (dirty) {
    if (!error && required === true && size(trim(value)) === 0) {
      setError(true);
      setErrorMsg('Dit is een verplicht veld');
    } else if (!error && type === 'email' && !emailCheck.test(value)) {
      setError(true);
      setErrorMsg('Dit is geen geldig e-mailadres');
    } else if (error && ((required === true && size(trim(value)) > 0 ) || (type === 'email' && emailCheck.test(value)))) {
      setError(false);
      setErrorMsg('');
    }
  }

  return (
    <>
      <InputLabel className={classes.label} id={id}>{label}</InputLabel>
        <FormControl className={classes.xs12} variant="outlined">
          <TextField
            id={id}
            key={id}
            error={error}
            helperText={errorMsg}
            defaultValue={value}
            onChange={(event) => onUpdate(event.target.value)} placeholder={placeholder}
            variant="outlined"
          />
      </FormControl>
    </>
  );
}