import React from 'react';
import './UnsubscribeForm.css';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Typography,
  // Button,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { get, padStart } from 'lodash';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import clsx from  'clsx';

const dateFormat = new Intl.DateTimeFormat('nl-NL', { month: 'long', year: 'numeric', day: 'numeric' });

export interface IService {
  additionalNamesList: string[];
  canBeUnsubscribed: boolean
  count: number;
  emailAddress: string;
  firstName: string;
  id: number;
  lastName: string;
  phoneNumber: string;
  service: {
    availableSeats: 250
    church: {
      name: string;
    },
    dateTime: string;
    day: {
      date: string;
      description: string,
      offertory: string;
      prayerTopics: string;
    }
    description: string;
    preacher: {
      fullName: string;
    }
    scriptureReading: string;
    scriptureText: string;
    subscribedSeats: 0
    time: {
      days: number;
      hours: number;
      minutes: number;
      seconds: number;
      milliseconds: number;
    }
    totalSeats: number;
  }
  serviceId: number;
  termsAndConditions: boolean;
  token: string;
}

const useStyles = makeStyles(theme => ({
  text: {
    color: '#333333',
    fontSize: 16,
    fontFamily: 'Open Sans',
    fontWeight: 600,
  },
  info: {
    color: '#333333',
    fontSize: 15,
    fontFamily: 'Open Sans',
  },
  submitBtn: {
    paddingTop: '16px',
  },
  error: {
    color: '#d50000',
  },
  attendees: {
    padding: 0,
    '& > .MuiListSubheader-root': {
      lineHeight: '24px',
      color: '#6C6C6C',
    },
    '& > li': {
      padding: 0,
      '&:last-child': {
        marginBottom: 16
      }
    }
  }
}))

function UnsubscribeForm() {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams() as { token: string };
  const [token] = React.useState(params.token);
  const [serviceResponse, setServiceResponse] = React.useState<IService>();
  const [serviceDate, setServiceDate] = React.useState<Date>();
  const [serviceTime, setServiceTime] = React.useState<string>();

  const submitForm = async (cancelToken: string) => {
    if (token) {
      try {
        const response = await axios.post(`https://gku-api.gku-pkn.nl/subscriptions/unsubscribe/${cancelToken}`);
        const success = get(response, 'data.success', false);
        history.push('/cancellation', {
          success,
          message: get(response, 'data.message', ''),
        });
      } catch (error) {
        console.error('Error while trying to cancel', error);
      }
    }
  };

  React.useEffect(() => {
    axios.get(`https://gku-api.gku-pkn.nl/subscriptions/${token}`)
    .then((response: { data: IService }) => {
      setServiceResponse(response.data);
    })
  }, [token]);

  React.useEffect(() => {
    if (serviceResponse?.service?.day.date) {
      setServiceDate(new Date(serviceResponse.service.day.date));
    }

    if (serviceResponse?.service?.time) {
      setServiceTime(`${padStart((serviceResponse?.service.time?.hours + ''), 2, '0')}:${padStart((serviceResponse?.service?.time?.minutes + ''), 2, '0')}`);
    }
  }, [serviceResponse]);

  return (
    <>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        >
          {serviceResponse && (
            <Grid item className="padding">
              <h4>Afmelden voor kerkdienst</h4>
              <p>Je kan eenvoudig afmelden voor de onderstaande kerkdienst door op de knop afmelden te klikken. Let op; vanaf een bepaalde moment is het niet mogelijk om af te melden.</p>
              <Grid item className="container">
                <Typography variant="subtitle2" gutterBottom>Kerk</Typography>
                <Typography variant="body1" className={classes.text} gutterBottom>{serviceResponse?.service?.church?.name}</Typography>
                <Typography variant="subtitle2" gutterBottom>Datum</Typography>
                <Typography variant="body1" className={classes.text} gutterBottom>{dateFormat.format(serviceDate)}</Typography>
                <Typography variant="subtitle2" gutterBottom>Aanvangstijd</Typography>
                <Typography variant="body1" className={classes.text} gutterBottom>{serviceTime}</Typography>
                <Typography variant="subtitle2" gutterBottom>Voornaam</Typography>
                <Typography variant="body1" className={classes.text} gutterBottom>{serviceResponse.firstName}</Typography>
                <Typography variant="subtitle2" gutterBottom>Achternaam</Typography>
                <Typography variant="body1" className={classes.text} gutterBottom>{serviceResponse.lastName}</Typography>
                <Typography variant="subtitle2" gutterBottom>Emailadres</Typography>
                <Typography variant="body1" className={classes.text} gutterBottom>{serviceResponse.emailAddress}</Typography>
                <Typography variant="subtitle2" gutterBottom>Aantal personen</Typography>
                <Typography variant="body1" className={classes.text} gutterBottom>{serviceResponse.count}</Typography>
                <List component="nav" className={classes.attendees} subheader={<ListSubheader>Deelnemers</ListSubheader>}>
                  {serviceResponse?.additionalNamesList?.map((name, idx ) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        <PersonAddIcon />
                      </ListItemIcon>
                      <ListItemText primary={name} />
                    </ListItem>
                  ))}
                </List>
                {serviceResponse.canBeUnsubscribed && (
                  <Typography variant="body1" color="secondary" className={classes.info} gutterBottom>Het is nog mogelijk om deze registratie af te melden.</Typography>
                )}
                {!serviceResponse.canBeUnsubscribed && (
                  <Typography variant="body1" className={clsx(classes.info, classes.error)} gutterBottom>Het is helaas niet meer mogelijk om deze registratie af te melden.</Typography>
                )}
                <Grid item className={classes.submitBtn}>
                  <Button fullWidth
                    variant="contained"
                    disabled={!serviceResponse.canBeUnsubscribed}
                    onClick={() => {
                      submitForm(token);
                    }}
                    color="primary">
                    Afmelden
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!serviceResponse && (
            <Grid item className="padding">
              <h4>Afmelden voor kerkdienst</h4>
              <p>Helaas kunnen we voor deze link geen registratie vinden.</p>
            </Grid>
          )}
        </Grid>
    </>
  );

}

export default UnsubscribeForm;