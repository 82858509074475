import React, { useEffect } from 'react';
import './RegistrationForm.css';
import { Grid, FormControl, InputLabel, Select, MenuItem, makeStyles, Checkbox, FormGroup, FormHelperText, Button, LinearProgress, ListSubheader } from '@material-ui/core';
import { InputField } from './components/InputField';
import { useRegistration, IRegistration } from './cache/Registration';
import { IService, useServices } from './cache/Services';
import { padStart, each, size, trim, get, uniqBy, isObject } from 'lodash';
import { PersonField } from './components/PersonField';
import { Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const dateFormat = new Intl.DateTimeFormat('nl-NL', { month: 'short', year: 'numeric', day: 'numeric' });

const useStyles = makeStyles(theme => ({
  xs12: {
    width: '100%',
  },
  checkbox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '30px',
    flexWrap: 'nowrap',
  },
  label: {
    color: '#333333',
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '19px',
    fontFamily: 'Open Sans',
    paddingBottom: '8px',
  },
  submitBtn: {
    paddingTop: '16px',
  }
}))

const RegistrationForm: React.FC<{
  existingRegistration?: IService;
}> = ({ existingRegistration }) => {

  const [registration, setRegistration, initialized] = useRegistration({
    acknowledged: false,
    noCovidSymptoms: false,
    emailAddress: '',
    firstname: '',
    lastname: '',
    persons: [],
    phoneNumber: '',
    type: 'registration',
    noCache: isObject(existingRegistration),
    remindMe: false,
  });

  const [dirty, setDirty] = React.useState(false);
  const [services] = useServices();
  const [selectedService, setSelectedService] = React.useState(-1);
  const [selectedChurch, setSelectedChurch] = React.useState(1);
  const history = useHistory();

  const isFilled = (value: string) => size(trim(value)) > 0;

  const submitForm = async (submission: IRegistration) => {
    if (submission) {
      if (
        isFilled(submission.firstname)
        && isFilled(submission.lastname)
        && isFilled(submission.emailAddress)
        && isFilled(submission.phoneNumber)
        && submission.acknowledged === true
        && submission.noCovidSymptoms === true
      ) {
        let personsAreFilled = true;
        each(submission.persons, (person) => {
          if (!isFilled(person)) {
            personsAreFilled = false;
          }
        });
        if (personsAreFilled && selectedService !== -1) {
          try {
            if (!existingRegistration) {
              const response = await axios.post('https://gku-api.gku-pkn.nl/subscriptions/subscribe', {
                'serviceId': selectedService,
                'emailAddress': registration.emailAddress,
                'firstName': registration.firstname,
                'lastName': registration.lastname,
                'phoneNumber': registration.phoneNumber,
                'termsAndConditions': registration.acknowledged,
                'additionalNames': registration.persons,
                'remindMe': registration.remindMe,
              });
              const success = get(response, 'data.success', false);
              history.push('/result', {
                success,
                message: get(response, 'data.message', ''),
                type: 'create',
              });
            } else {
              const response = await axios.put(`https://gku-api.gku-pkn.nl/subscriptions/${existingRegistration.token}`, {
                'emailAddress': registration.emailAddress,
                'firstName': registration.firstname,
                'lastName': registration.lastname,
                'phoneNumber': registration.phoneNumber,
                'termsAndConditions': registration.acknowledged,
                'additionalNames': registration.persons,
                'remindMe': registration.remindMe,
              });
              const success = get(response, 'data.success', false);
              history.push('/result', {
                success,
                message: get(response, 'data.message', ''),
                type: 'update'
              });
            }
          } catch (error) {
            console.error('Error while trying to register', error);
          }
        } else {
          console.warn('Some persons not filled in');
        }
      } else {
        console.warn('Required fields not filled');
      }
    } else {
      console.warn('No submission to send');
    }
  };

  const selectChurchHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedChurch(event.target.value as number);
    setSelectedService(-1);
  };

  const selectServiceHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedService(event.target.value as number);
  };

  const renderAvailableChurches = () => {
    const uniqueChurchesServices = uniqBy(services, 'churchId');
    if (uniqueChurchesServices?.length > 0) {
      return uniqueChurchesServices
      .map((service, idx) => {
        return (<MenuItem
            key={idx}
            value={service.churchId}>
             {service.church?.name ?? 'Onbekend'}
          </MenuItem>
          );
        ;
      });
    } else {
      return (<MenuItem
        disabled={true}
        value={-1}>
         Op dit moment zijn er geen diensten beschikbaar
      </MenuItem>);
    }
  };

  // If there is an existing registration, fill in the form
  //
  useEffect(() => {
    if (existingRegistration) {
      setRegistration({
        firstname: existingRegistration.firstName,
        lastname: existingRegistration.lastName,
        emailAddress: existingRegistration.emailAddress,
        phoneNumber: existingRegistration.phoneNumber,
        type: 'registration',
        acknowledged: true,
        noCovidSymptoms: true,
        persons: existingRegistration.additionalNamesList,
      });
      setSelectedChurch(existingRegistration.service.churchId);
      setSelectedService(existingRegistration.service.id);
    }
  }, [existingRegistration, setRegistration]);

  const renderOptions = () => {
    let previousGroupName: string;
    return services
    .filter((service) => service.churchId === selectedChurch)
    .map((service) => {
      const serviceDate = new Date(service.day.date);
      const time = `${padStart((service?.time?.hours + ''), 2, '0')}:${padStart((service?.time?.minutes + ''), 2, '0')}`
      let churchName = 'De Poort';
      if (service.churchId === 2) {
        churchName = 'Petrakerk';
      } else if (service.churchId === 3) {
        churchName = 'Bethelkerk';
      } else if (service.churchId === 11) {
        churchName = '  ';
      }
      const currentGroupName =service?.description ?? 'Eredienst';
      let groupName;
      if (!previousGroupName || currentGroupName !== previousGroupName) {
        groupName = currentGroupName;
      }
      previousGroupName = currentGroupName;

      return [
        (groupName ? (<ListSubheader>{groupName}</ListSubheader>) : (<div></div>)),
        (<MenuItem
          disabled={!service.openForSubscriptions || service.availableSeats < 0}
          value={service.id}>
            {dateFormat.format(serviceDate)} - {time}&nbsp;&nbsp;{churchName}&nbsp;
            {service.openForSubscriptions ? (
              <span className="available-seats">(nog {service.availableSeats} beschikbaar)</span>
            ) : (
              <span className="available-seats">(volgeboekt)</span>
            )}
        </MenuItem>)
      ];
    });
  };

  const updateRegistration = (update: Partial<IRegistration>) => {
    setRegistration((state) => ({
      ...state,
      ...update,
    }));
  };

  const classes = useStyles();

  if (!initialized) {
    return (<LinearProgress />);
  }

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
    >
      <Grid item className="padding">
        {existingRegistration ? (
          <>
            <h4>Registatie aanpassen</h4>
            <p>Vul het onderstaande formulier in om je registratiegegevens aan te passen.</p>
          </>
        ) : (
          <>
            <h4>Aanmelden kerkdienst</h4>
            <p>Vul het onderstaande formulier in om je aan te melden voor een kerkdienst.</p>
          </>
        )}
        <Grid item className="container">
          <Grid
            container
            spacing={2}
            direction="column"
            justify="flex-start"
            alignItems="center">
            <Grid className={classes.xs12} item>
              <InputLabel className={classes.label} id="choose-a-church">Kies een kerkgebouw</InputLabel>
              <FormControl className={classes.xs12} variant="outlined">
                <Select
                  labelId="choose-a-church"
                  disabled={isObject(existingRegistration)}
                  onChange={selectChurchHandler}
                  value={selectedChurch}
                >
                  {renderAvailableChurches()}
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.xs12} item>
              <InputLabel className={classes.label} id="choose-a-service">Kies een dienst</InputLabel>
              <FormControl className={classes.xs12} variant="outlined" error={dirty && selectedService === -1}>
                <Select
                  labelId="choose-a-service"
                  onChange={selectServiceHandler}
                  disabled={isObject(existingRegistration)}
                  value={selectedService}
                >
                  <MenuItem disabled key={-1} value={-1}>Selecteer een dienst die je wil bijwonen</MenuItem>
                  {renderOptions()}
                </Select>
                {dirty && selectedService === -1 && (<FormHelperText>Je moet een dienst selecteren</FormHelperText>)}
              </FormControl>
            </Grid>
            <Grid className={classes.xs12} item>
              <InputField
                id="firstname"
                placeholder="Voornaam"
                label="Voornaam"
                required={true}
                dirty={dirty}
                value={registration.firstname ?? ''}
                onUpdate={(firstname) => updateRegistration({firstname})}
              ></InputField>
            </Grid>
            <Grid className={classes.xs12} item>
              <InputField
                id="lastname"
                placeholder="Achternaam"
                label="Achternaam"
                required={true}
                dirty={dirty}
                value={registration.lastname ?? ''}
                onUpdate={(lastname) => updateRegistration({lastname})}
              ></InputField>
            </Grid>
            <Grid className={classes.xs12} item>
              <InputField
                id="emailaddress"
                placeholder="E-mailadres"
                label="E-mailadres"
                dirty={dirty}
                type="email"
                value={registration.emailAddress ?? ''}
                onUpdate={(emailAddress) => setRegistration((state) => ({
                  ...state,
                  emailAddress
                }))}
              ></InputField>
            </Grid>
            <Grid className={classes.xs12} item>
              <InputField
                id="phonenumber"
                placeholder="0612345678"
                label="Telefoonnummer"
                required={true}
                dirty={dirty}
                value={registration.phoneNumber ?? ''}
                onUpdate={(phoneNumber) => setRegistration((state) => ({
                  ...state,
                  phoneNumber
                }))}
              ></InputField>
            </Grid>
              <PersonField
                id="persons"
                items={registration.persons}
                label="Meer gezinsleden aanmelden (max. 6)"
                dirty={dirty}
                primaryGuestname={`${registration.firstname} ${registration.lastname}`}
                onUpdate={(persons) => setRegistration((state) => ({
                  ...state,
                  persons
                }))}
              ></PersonField>
          </Grid>
          {!existingRegistration && (
            <>
              <Grid item>
                <FormControl required error={dirty && !registration.acknowledged} component="fieldset">
                  <FormGroup className={classes.checkbox}>
                    <Checkbox
                      color="primary"
                      checked={registration.acknowledged}
                      onChange={(event) => setRegistration((state) => ({
                        ...state,
                        acknowledged: event.target.checked,
                      }))}
                      name="acknowledged"
                    />
                    <p>Ik ga akkoord met de <Link color="primary" href="https://www.gku-pkn.nl/im-new-here/" rel="noopener noreferrer" target="_blank">algemene voorwaarden</Link>
                      <span> en het <Link color="primary" href="https://www.gku-pkn.nl/privacyverklaring/" rel="noopener noreferrer" target="_blank">privacy beleid</Link>.</span></p>
                  </FormGroup>
                  {dirty && !registration.acknowledged && (<FormHelperText>Dit is een verplicht veld</FormHelperText>)}
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <FormGroup className={classes.checkbox}>
                    <Checkbox
                      color="primary"
                      checked={registration.remindMe}
                      onChange={(event) => setRegistration((state) => ({
                        ...state,
                        remindMe: event.target.checked,
                      }))}
                      name="remindeMe"
                    />
                    <p>Ik wil graag volgende keer een herinnering ontvangen als er de aanmeldingen opengaan.</p>
                  </FormGroup>
                  {dirty && !registration.noCovidSymptoms && (<FormHelperText>Dit is een verplicht veld</FormHelperText>)}
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item>
            <FormControl required error={dirty && !registration.noCovidSymptoms} component="fieldset">
              <FormGroup className={classes.checkbox}>
                <Checkbox
                  color="primary"
                  checked={registration.noCovidSymptoms}
                  onChange={(event) => setRegistration((state) => ({
                    ...state,
                    noCovidSymptoms: event.target.checked,
                  }))}
                  name="noCovidSymptoms"
                />
                <p>Ik verklaar {existingRegistration ? 'nog steeds' : 'momenteel'} geen corona gerelateerde klachten te ondervinden en thuis te blijven als hiervan wel sprake is.</p>
              </FormGroup>
              {dirty && !registration.noCovidSymptoms && (<FormHelperText>Dit is een verplicht veld</FormHelperText>)}
            </FormControl>
          </Grid>
          <Grid item className={classes.submitBtn}>
            <Button fullWidth
              variant="contained"
              onClick={() => {
                setDirty(true);
                submitForm(registration);
              }}
              color="primary">
                {existingRegistration ? 'Bijwerken' : 'Aanmelden'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default RegistrationForm;
