import React from 'react'; // we need this to make JSX compile
import { InputLabel, TextField, makeStyles, Grid, IconButton, Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { size, trim, isEqual } from 'lodash';
import cx from 'classnames';

type PersonFieldProps = {
  dirty: boolean;
  id: string;
  items: string[];
  label: string;
  onUpdate: (value: string[]) => void;
  primaryGuestname: string;
};

const useStyles = makeStyles(theme => ({
  xs12: {
    width: '100%',
  },
  label: {
    color: '#333333',
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '19px',
    fontFamily: 'Open Sans',
    paddingBottom: '8px',
    paddingTop: '8px'
  },
  personList: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minHeight: '58px',
    height: '100%',
  },
  personInput: {
    flex: 1,
    height: '100%',
  },
  paddingBottom: {
    marginBottom: '16px',
    width: '100%',
    minHeight: '58px',
    display: 'flex',
    flexDirection: 'row',
  },
  deleteBtn: {
    border: '1px solid #B94A48',
    padding: 0,
    width: '42px',
    height: '42px',
    borderRadius: '5px',
    marginLeft: '8px',
    color: '#B94A48',
  }
}))

export const PersonField = ({
  dirty,
  id,
  items = [],
  label,
  onUpdate,
  primaryGuestname,

}: PersonFieldProps) => {

  const classes = useStyles();

  const [data, setData] = React.useState<string[]>(items);

  React.useEffect(() => {
    if (!isEqual(items, data)) {
      onUpdate(data);
    }
  }, [data, items, onUpdate]);

  return (
    <>
      <InputLabel className={classes.label} id={id}>{label}</InputLabel>
      <Grid
        container
        className={classes.xs12}
        spacing={2}
        direction="column"
        justify="flex-start"
        alignItems="center">
        <Grid item className={classes.xs12}>
          <Button fullWidth
            variant="outlined"
            disabled={data.length >= 6}
            onClick={() => {
              setData((prevState) => [...prevState, '']);
              setTimeout(() => {
                document.getElementById(`person-${data.length}`)?.focus();
              }, 1000);
            }}
            color="primary">
            Persoon toevoegen
          </Button>
        </Grid>
        {primaryGuestname && primaryGuestname.trim().length > 0 && (
          <Grid item xs className={classes.paddingBottom}>
            <TextField
              value={primaryGuestname}
              className={classes.personInput}
              disabled={true}
              helperText="Naam hoofdboeker (automatisch ingevuld)"
              variant="outlined"
            />
          </Grid>
        )}
        {data.map((person, idx) => (
          <Grid key={idx} item xs className={
            cx(classes.personList, {
              personError: dirty && size(trim(data[idx])) === 0
            })}>
            <TextField
              defaultValue={person}
              className={classes.personInput}
              autoFocus={idx === (data.length - 1)}
              id={`person-${idx}`}
              error={dirty && size(trim(data[idx])) === 0}
              onChange={(event) => {
                setData((prevState) => {
                  return prevState.map((value, index) => {
                    if (index === idx) {
                      return event.target.value;
                    } else {
                      return value;
                    }
                  } );
              })}}
              helperText={dirty && size(trim(person)) === 0 ? 'Dit is een verplicht veld': ''}
              placeholder={'Naam persoon ' + (idx+1)}
              variant="outlined"
            />
            <IconButton
              onClick={() => setData(persons => persons.filter((value, index) => index !== idx))}
              aria-label="delete"
              tabIndex={-1}
              className={classes.deleteBtn}
            >
              <Delete/>
            </IconButton>
          </Grid>
        ))}
      </Grid>
    </>
  );
}