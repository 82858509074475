import Dexie from 'dexie';

export class AppDatabase extends Dexie {
  constructor() {

    super("GkuRegistration");

    var db = this;

    // Define tables and indexes
    //
    db.version(1).stores({
      registration: '&type',
      persons: '++id, fullName',
    });
  }
}