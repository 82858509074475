import { AppDatabase } from "./AppDatabase";
import React from "react";
import { isBoolean, isEqual } from "lodash";

export interface IRegistration {
  firstname: string;
  lastname: string;
  emailAddress: string;
  phoneNumber: string;
  type: string;
  acknowledged: boolean;
  noCovidSymptoms: boolean;
  persons: string[];
  remindMe?: boolean;
}

export class RegistrationCache {

  table: Dexie.Table<IRegistration, string>;

  constructor() {
    const db = new AppDatabase();
    this.table = db.table('registration');
  }

  async get(type: string) {
    return this.table.get(type);
  }

  async put(registration: IRegistration) {
    this.table.put(registration);
  }
}

export function useRegistration(defaultRegistration: IRegistration & { noCache: boolean}): [IRegistration, React.Dispatch<React.SetStateAction<IRegistration>>, boolean] {

  const cache = new RegistrationCache();
  const [registration, setRegistration] = React.useState<IRegistration>(defaultRegistration);
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    async function fetchRegistration() {
      try {

        const cachedValue = await cache.get('registration');
          if (cachedValue && !isEqual(registration, cachedValue)) {
            setRegistration(cachedValue);
          }
          setInitialized(true);

      } catch (error) {
        console.error('Could not fetch registration data', error);
      }
    }

    if (!defaultRegistration.noCache || (isBoolean(defaultRegistration.noCache) && !defaultRegistration.noCache)) {
      fetchRegistration();
    } else {
      setInitialized(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    ( async () => {
      if (!defaultRegistration.noCache) {
        const cachedValue = await cache.get('registration');
        if (!cachedValue || (cachedValue && !isEqual(registration, cachedValue))) {
          cache.put(registration);
        }
      }
    } )();

  }, [registration, cache, defaultRegistration]);

  return [registration, setRegistration, initialized];
}
